@media (max-width: 850px) {
  .MobileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .DeskContainer {
    display: none;
  }

  .ImageContainer {
    max-width: 100%;
    z-index: -1;
    padding-bottom: 30px;
  }

  .Image {
    width: 100%;
  }

  .ContentMobile {
    text-align: center;
    padding-bottom: 30px;
    width: 80%;
  }

  .Title {
    width: 80%;
    text-align: center;
  }

  .ButtonContainer {
    padding-top: 50px;
  }
}

@media (min-width: 850px) {
  .MobileContainer {
    display: none;
  }

  .DeskContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .TIContainer {
    text-align: center;
    max-width: 300px;
    margin-left: 10%;
  }

  .SectionTitle {
    font-family: CooperBT;
    font-size: 30px;
    font-weight: 300;
  }

  .ImageContainer {
    max-width: 660px;
    z-index: -1;
  }

  .ButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }

  .Image {
    object-fit: cover;
    border-radius: 0 0 40px 40px;
  }
}

@media (max-width: 500px) {
  .ImageContainer {
    max-width: 100%;
    z-index: -1;
    padding-bottom: 0px;
  }

  .Image {
    width: 100%;
    object-position: 0 -50px;
  }

  .ButtonContainer {
    padding-top: 30px;
  }
}

.InfoContainer {
  display: flex;
  flex-direction: column;
}

.Title {
  font-family: ModernEra;
  font-size: 30px;
  font-weight: bolder;
  padding-bottom: 15px;
}

.Text {
  font-family: ModernEra;
  font-size: 16px;
  line-height: 20px;
}
