.Container {
  height: 80px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  align-items: center;
}

@media (max-width: 650px) {
  .ImgLogo {
    padding-left: 20px;
  }
}

@media (min-width: 651px) {
  .ImgLogo {
    padding-left: 120px;
  }
}
