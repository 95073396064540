.containerFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heightContainer {
  min-height: 450px;
}

.textCenter {
  text-align: center;
}

.buttonNext {
  height: 45px;
  border-radius: 8px;
  box-shadow: 5px 5px 15px 0 rgba(7, 82, 249, 0.25);
  background-color: #00d221;
  color: white;
  font-size: 15px;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.buttonNextCita {
  padding: 0 15px;
}

.Whats {
  padding-left: 10px;
  height: 15px;
}

.Title {
  padding-top: 30px;
  font-family: ModernEra;
  font-size: 26px;
  font-weight: 800;
  text-align: center;
}

.container div {
  margin: 20px;
  font-family: ModernEra;
}

.inp {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 280px;
  box-sizing: border-box;
}
.inp .label {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 16px;
  color: #9098a9;
  font-weight: 500;
  transform-origin: 0 0;
  transition: all 0.2s ease;
  box-sizing: border-box;
}
.inp .border {
  position: absolute;
  bottom: -16px;
  left: 0;
  height: 2px;
  width: 100%;
  background: #07f;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: all 0.15s ease;
  box-sizing: border-box;
}
.inp input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  padding: 12px 0;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #c8ccd4;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
  box-sizing: border-box;
}
.inp input:hover {
  background: rgba(34, 50, 84, 0.03);
  box-sizing: border-box;
}
.inp input:not(:placeholder-shown) + span {
  color: #5a667f;
  transform: translateY(-30px) scale(0.75);
  box-sizing: border-box;
}
.inp input:focus {
  background: none;
  outline: none;
  box-sizing: border-box;
}
.inp input:focus + span {
  color: #07f;
  transform: translateY(-30px) scale(0.75);
  box-sizing: border-box;
}
.inp input:focus + span + .border {
  transform: scaleX(1);
  box-sizing: border-box;
}

.error {
  color: red;
  margin-left: 20px;
  margin-top: -20px;
  text-align: left;
  font-family: ModernEra;
  font-size: 14px;
}

@media (min-width: 800px) {
  .title {
    font-size: 28px;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 20px;
    width: 400px;
  }

  .nextContainer {
    padding-top: 30px;
  }

  label {
    font-size: 16px;
  }
}

@media (max-width: 799px) {
  .title {
    font-size: 24px;
    text-align: center;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 350px;
    min-width: 270px;
    width: 100%;
  }

  .nextContainer {
    position: relative;
    bottom: -20px;
  }

  label {
    font-size: 16px;
    margin: 10px 0px 10px 0px;
  }
}
