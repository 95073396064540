.Content {
  min-height: 160px;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ModernEra;
  font-size: 14px;
  color: #878787;
  line-height: 20px;
  text-align: center;
}

@media (max-width: 700px) {
  .Content {
    background-color: #f3f3f3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: ModernEra;
    font-size: 11px;
    color: #878787;
    line-height: 15px;
    text-align: center;
    padding: 0px 25px;
  }
}
