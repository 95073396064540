.CarrouselContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Carrousel {
  width: 80%;
}

.SubTitle {
  font-family: CooperBT;
  font-size: 30px;
  text-align: center;
  font-weight: normal;
}

.CarouselContainer {
  width: 97.5%;
  max-width: 900px;
  margin-top: 100px;
}

.Image {
  width: 100%;
  height: 500px;
}

@media (max-width: 650px) {
  .WavesContainer {
    position: absolute;
    margin-top: -50px;
    z-index: -1;
    width: 100%;
  }

  .Carrousel {
    width: 100%;
    height: 510px;
  }

  .FixButton {
    display: none;
  }

  .Container {
    position: relative;
  }

  .Fix {
    position: fixed;
    bottom: 10%;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
  }

  .FixDinamic {
    position: absolute;
    bottom: -3%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 650px) {
  .WavesContainer {
    display: none;
  }

  .FixDinamic {
    display: none;
  }

  .Fix {
    display: none;
  }
}

/* @media (max-width: 650px) and (max-height: 820px) {
  .Fix {
    position: fixed;
    bottom: 23%;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
  }
}
 */
